<template>
	<!-- flex_column -->
	<div class="aircraftBrand_page">
		<!-- 飞机库组件 -->
		<hangar :isPhone="isPhone" :buttonList="buttonList" @curBtn="changeIndex" :desc="$t('aircraftBrand.desc')" :curBtn="curBtn" :title="$t('aircraftBrand.name')"></hangar>
		<div class="zui-flex-row zui-center-center zui-flex-1">
			<div class="mainBox">
		
				<div v-for="(item,i) in listData" :key="i" class="grid-content bg-purple zui-bg-white zui-overflow-hidden zui-relative"  @click="toBrandDetail(item)" :id="'id'+item.id.toString()">
					<el-image class="plane-img zui-border-radius-small" style="width: 100%;" :src="$apiUrl+'/'+item.brandImg" fit="fill"></el-image>
				</div>
				
			</div>
		</div>
		<!-- 返回按钮 -->
		<div id="back"  v-show="isPhone" @click="back" style="position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
	</div>
</template>

<script>
	import hangar from '../components/hangarCom1';
	import {
		getAirBrandPavilion,
		getAirPlaneBrandList,
		// getAirPlaneBrand,
		// getListByPlaneType
	} from '../api/api';
	// 飞机品牌页面
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				isPhone:false,
				buttonList: [{id:0,name:'全部品牌'}],
				// buttonList1: [
				// 	{id:0,name:'制造商'},{id:1,name:'金融租赁'},{id:2,name:'运营商'},{id:3,name:'MRO5'},{id:4,name:'异业联盟'}
				// ],
				curBtn: 0,
				listData: []
			};
		},
		watch: {
			language(){
				var _that=this
				this.buttonList[0].name=_that.$t('aircraftBrand.mseg')
			},
			$route(from) {
				// 监听路由变化, 实现类似 小程序的 onShow 事件
				if (from.params.id) {
					this.curBtn = from.params.id - 1;
				} else {
					this.curBtn = 0;
				}

			}
		},
		mounted() {
			var _that=this
			this.buttonList[0].name=_that.$t('aircraftBrand.mseg')
			let id = this.$route.params.id
			//console.log(id)
			if (id) {
				setTimeout(()=>{
					this.to_scroll(id)
				},500)
				
			}
		},
		created: function() {
			if(/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)){
				this.isPhone=true
			}
			this.airPlaneType();
		},
		computed: {
			...mapState(['language'])
		},
		methods: {
			to_scroll(e = 0) {
				let posi = 'id'+e.toString()
				if (posi) {
					var dom = document.getElementById(posi)
			
					if (dom) {
						dom.scrollIntoView({
							behavior: "smooth", // 平滑过渡
							block: "center" // 滚到屏幕中间
						})
					}
			
				}
			},
			airPlaneType: function() {
				getAirBrandPavilion().then(res => {
					// console.log(res);
					if (res.code == 200) {
						let data = res.result.records;
						this.$zui.arrayEach(data, (item, index) => {
							let temp = {};
							temp.name = item.typeName
							temp.id = index+1
							temp.typeId = item.id
							this.buttonList.push(temp);
						})
						//获取数据
						this.airPlaneBrand();
						
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			airPlaneBrand: function(airPlaneType = 0) {
				let queryData = {};
				queryData._t = Date.parse(new Date())
				
				if(this.curBtn > 0){
					queryData.airBrandPavilion = airPlaneType
					
				}
				
				queryData.column = 'createTime'
				queryData.order = 'desc'
				// queryData.field = 'id,,,brandName,brandImg,brandContent,airPlaneType_dictText,action'
				queryData.pageNo = 1
				queryData.pageSize = 99
				getAirPlaneBrandList(queryData).then(res => {
					// console.log(res);
					if (res.code == 200) {
						// console.log(res);
						this.listData = res.result.records;
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			changeIndex(e) {
				this.curBtn = e
				// console.log(this.buttonList[e].typeId);
				this.airPlaneBrand(this.buttonList[e].typeId);
			},
			toBrandDetail(item) {
				let queryData = {};
				queryData.planeInfo = item
				this.$router.push({
					name: 'aircraftBrandDetail',
					params: queryData
				});
			}
		},
		components: {
			hangar
		}
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/config.scss';
	@import '../assets/scss/mixin.scss';
	.mainBox {
		width: 80%;
		background-color: white;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 25px 0;
		justify-content: flex-start;
		align-content: flex-start;
		margin:0 -20px;
		// flex-shrink: 1;
		//品牌宽高
		.grid-content {
			border-radius: 4px;
			border:1px solid white;
			// width: 15%;
			width: calc(16% - 40px);
			margin: 20px;

			box-sizing: border-box;

			&:hover {
				border-color:#DBB96E;
			}
			/deep/ .plane-img {
				padding-top: 60%;
				width: 100%;
				position: relative;
				img {
					position: absolute;
					width: 100%;
					height: 100%;
					top:0;
				}
			}
		}
		
	}
	@media only screen and (max-width : 992px) {
		.mainBox {
			width: 90%;
			margin:0 -10px;
			padding: 10px 0;
			.grid-content {
				width: calc(18% - 20px);
				margin: 10px;
			}
			
		}
	}
	@media only screen and (max-width : 768px) {
		.mainBox {
			width: 90%;
			margin:0 -10px;
			padding: 10px 0;
			.grid-content {
				width: calc(30% - 20px);
				margin: 10px;
			}
			
		}
	}
</style>
