<template>
	<div>
		<div class="hangar_pages_top flex_column zui-relative">
			<div class="flex_5 flex_column">{{ title }}</div>
			<div class="planeTile">{{desc}}</div>
			<div v-if="buttonList.length > 0" class="flex_5 flex_row zui-flex-wrap">
				<el-button size="mini" v-for="(item, index) in buttonList" :key="index" :class="item.id == curBtn ? 'btnColor act' : 'btnColor'" plain @click="clickBtn(item.id)">
					{{ item.name }}
				</el-button>
			</div>
			<div class="btnblock header_r padding-0 col-md-1  col-sm-2 col-xs-3">
				<div v-if="!isLogin" class="zui-h-100 zui-pointer zui-flex zui-center-center log" style="font-size: 12px;">{{$t('login.login')}}</div>
				<div class="btnCenter">
					<span @click="back" v-show="!isPhone" class="jinBtn cursor  zui-text-white back">{{$t('common.back')}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
export default {
	props: {
		isPhone:Boolean,
		buttonList: Array,
		title: String,
		desc:String,
		curBtn: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {};
	},
	computed: {
		...mapState(['isLogin']),
	},
	methods: {
		clickBtn(id) {
			//this.curBtn = id;
			this.$emit('curBtn', Number(id));
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/config.scss';
@import '../assets/scss/mixin.scss';
.log{
	padding-right: 12px;
	// font-weight: bold;
	color: #dbb96e;
	opacity: 0;
	-webkit-user-select: none;
	cursor: default;
}
.el-button{
	flex-basis: 0% ;
}
.btnblock{
	width: 8.333333333%;
	position: absolute;
	// height: 100px;
	right: 0;
	display: flex;
	justify-content: center;
}
.btnCenter{
	height: 26px;
	width: 40px;
	display: flex;
	justify-content: center;
}

.back {
	padding: 4px 8px;
	font-size: 10px;
	right:5%;
	top:20%;
	border-radius: 4px;
}
.hangar_pages_top {
	width: 100%;
	height: 138px;
	background: url('../assets/image/15.jpg') no-repeat center;
	background-size: 100% 100%;
	div {
		color: $colorJ;
		font-size: $fontD;
	}
}
			.planeTile {
				text-align: center;
				font-size: 14px !important;
			}
.btnColor {
	// width: 147px;
	// height: 32px;
	background: transparent;
	border: 1px solid #dbb96e;
	border-radius: 4px;
	color: #dab96b;
	text-transform: uppercase;
	// padding: 4px 6px;
}
.act {
	background-color: $colorJ;
	color: $colorG;
}
.el-button.is-plain:focus,
.el-button.is-plain:hover {
	background: #dbb96e;
	border-color: #dab96b;
	color: white;
}

@media only screen and (max-width: 1170px) {
		/deep/ .log {
			padding-right: 1%;
			
		}
	}
@media only screen and (max-width: 992px) {
	.btnblock{
		width: 33.333333%;
	}
}
@media only screen and (max-width: 960px) {
	.log {
				padding-right: 10px;
	
			}
}

//平板
@media only screen and (max-width: 768px) {
	.btnColor {
		padding: 4px;
	}
	.btnblock{
		width: 25%;
	}
}
</style>
